<template>
  <div class="admin-container" v-if="data">
    <v-row>
      <v-col cols="12" md="12">
        <v-btn tile small @click="$router.push({ name: 'PhotoGallery' })"
          >Zpět</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="justify-space-between">
      <v-col cols="12" md="4">
        <h1 class="col-secondary-1" style="font-size: 16px; font-weight: 400">
          {{ data.name }}
        </h1>
        <span class="d-block col-secondary-3"
          ><span class="d-inline-block mr-2"
            >{{ data.documents.length }}
            {{
              data.documents.length == 1
                ? "obrázek"
                : data.documents.length > 1 && data.documents.length < 5
                ? "obrázky"
                : "obrázků"
            }}</span
          >
        </span>
      </v-col>
      <v-col class="flex-grow-0 ml-auto">
        <v-btn
          x-small
          tile
          color="secondary"
          height="32"
          class="mobile-width-send-message"
          @click="downloadAll"
          ><v-icon small color="primary">mdi-download</v-icon> Stáhnout
          vše</v-btn
        >
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col class="flex-grow-0 ml-auto">
        <v-btn
          x-small
          tile
          color="secondary"
          height="32"
          class="mobile-width-send-message"
          @click="downloadAll"
          ><v-icon small color="primary">mdi-download</v-icon> Stáhnout
          vše</v-btn
        >
      </v-col>
    </v-row> -->
    <v-row class="photo-gallery-wrapper-detail">
      <div
        class="photo-gallery-single-item-detail"
        v-for="(image, imageIdx) in data.documents"
        :key="image.id"
      >
        <div class="photo-gallery-single-item-detail-image-wrapper">
          <img
            class="photo-gallery-single-item-detail-image cursor-pointer"
            :src="image.path"
            :alt="image.name"
            @click="downloadDoc(image.documentId)"
          />
          <div class="photo-gallery-single-item-date">
            {{ image.createdAt }}
          </div>
          <div class="document-close" style="top: 12px">
            <button
              @click="downloadSingle(image)"
              style="
                top: -1px;
                position: absolute;
                right: 2px;
                text-decoration: none;
              "
            >
              <v-icon style="color: #dba822 !important; font-size: 22px"
                >mdi-download</v-icon
              >
            </button>
          </div>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
import authHandler from "../api/authHandler";
import permissionDeniedHandler from "../api/permissionDeniedHandler";
import bus from "../plugins/bus";
import errorHandler from "../api/errorHandler";
import photoGalleryService from "../api/photoGalleryService";
import documentService from "../api/documentService";
import downloadService from "../api/downloadService";
import axios from "axios";

export default {
  data() {
    return {
      data: null,
    };
  },
  async created() {
    try {
      //   await this.$store.dispatch("getCurrentUser");
      if (this.getSelectedBuilding() != null) {
        await this.show();
      } else {
        this.$router.push({ name: "Dashboard" });
        bus.$emit("snackbar", {
          text: "Není vybrána žádná stavba.",
          color: "error",
        });
      }
    } catch (error) {
      if (!authHandler(error)) this.$router.push({ name: "Login" });
      permissionDeniedHandler(error, this.$router);
    }
  },
  methods: {
    async downloadSingle(image) {
      try {
        if (image.path) {
          let filename = image.path.split("/");
          filename = filename[filename.length - 1];
          const getImage = await axios.get(image.path, {
            responseType: "blob",
          });
          const link = document.createElement("a");
          const downloadUrl = window.URL.createObjectURL(
            new Blob([getImage.data])
          );
          link.href = downloadUrl;
          link.target = "_blank";
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async downloadAll() {
      try {
        bus.$emit("processing");
        const { code } = await photoGalleryService.download(
          this.$route.params.id
        );
        await downloadService.downloadFromCode(code);
        bus.$emit("processing", false);
      } catch (error) {
        errorHandler(error);
        bus.$emit("processing", false);
      }
    },
    async downloadDoc(id) {
      try {
        bus.$emit("processing");
        await documentService.show(id);
        bus.$emit("processing", false);
      } catch (error) {
        bus.$emit("processing", false);
        errorHandler(error);
      }
    },
    getSelectedBuilding() {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      return building;
    },
    async show() {
      try {
        bus.$emit("processing");
        const data = await photoGalleryService.show(this.$route.params.id);
        this.data = data;
        console.log(data);
        bus.$emit("processing", false);
      } catch (error) {
        console.error(error);
        bus.$emit("processing", false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit("snackbar", {
            text: error.response.data.error.message,
            color: "error",
          });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    stahnout() {
      return {
        backgroundImage: `url(${require("@/assets/stahnout.svg")} !important`,
      };
    },
  },
};
</script>

<style></style>
